import React from 'react';
import logo from './logo.svg';
import './App.css'; 

import DelayLink from 'react-delay-link';
import { BrowserRouter, Route, Switch, useHistory, useLocation  } from 'react-router-dom';
 
import Pdf from  './media/Carlos-Contreras-Resume.pdf'; 
import Video from './media/mainBG.mp4';
import Video2 from './media/vid/bg.mp4';
 
import BrandingBG from "./media/branding/0.jpg"
import B1 from "./media/branding/1.png"
import B2 from "./media/branding/2.jpg"
import B3 from "./media/branding/3.jpg"
import B4 from "./media/branding/4.jpg"
import B5 from "./media/branding/5.jpg"
import B6 from "./media/branding/6.jpg"
import B7 from "./media/branding/7.gif"
import B8 from "./media/branding/08.jpg"
import B9 from "./media/branding/09.jpg"
import B10 from "./media/branding/10.jpg"
import B11 from "./media/branding/11.png"
import B12 from "./media/branding/12.jpg"
import B13 from "./media/branding/13.jpg"

import chela1 from "./media/branding/a.jpg"
import chela2 from "./media/branding/b.jpg"
import chela3 from "./media/branding/c.jpg"


import ABG from "./media/branding/4.jpg"
import BBG from "./media/branding/3.jpg"
import { render } from '@testing-library/react';
import Gif from './media/gif.gif' 
import Vid from './media/mainBG.mp4'
import Hersheys from './media/pelon1.jpg'

import Still from './media/vid/1.jpg';
import Circ from './media/circ0.jpg';
import Exp from './media/exp0.jpg';
import Ill from './media/ill0.jpg';
import Pelon1 from './media/pelon1.jpg';
import Pelon2 from './media/pelon2.jpg';

import Pelon3 from './media/pelon3.jpg';
import Pelon4 from './media/pelon4.jpg';
import PelonG from './media/pelon.gif';

import insta1 from './media/illustration/01.jpeg'
import insta2 from './media/illustration/02.jpeg'
import insta3 from './media/illustration/03.jpeg'
import insta4 from './media/illustration/04.jpeg'
import insta5 from './media/illustration/05.jpeg'
import insta6 from './media/illustration/06.jpeg'
import insta7 from './media/illustration/07.jpeg'
import insta8 from './media/illustration/08.jpeg'
import insta9 from './media/illustration/09.jpeg'
import insta10 from './media/illustration/10.jpeg'
import insta11 from './media/illustration/11.jpeg'
import insta12 from './media/illustration/12.jpeg'
import insta13 from './media/illustration/13.jpeg'
import insta14 from './media/illustration/14.jpeg'

import sb01 from './media/cw/sb01.jpg'
import sb02 from './media/cw/sb02.jpg'
import sb03 from './media/cw/sb-game.jpg'

import sb04 from './media/cw/sb-map.jpg'
import sb05 from './media/cw/sb05.jpg'
import sb06 from './media/cw/sb06.jpg'
import sb07 from './media/cw/sb07.jpg'
import sb08 from './media/cw/sb08.jpg'
import sb09 from './media/cw/sb09.jpg'
import sblogo from './media/cw/sbLogo.png'

import cb01 from './media/iw/CB01.jpg'
import cb02 from './media/iw/CB02.jpg'
import cb03 from './media/iw/CB03.jpg'
import cb04 from './media/iw/CB04.jpg'
import cb05 from './media/iw/CB05.jpg'
import cb06 from './media/iw/CB06.jpg'
import cb07 from './media/iw/CB07.jpg'
import cb08 from './media/iw/CB08.jpg'
import cb09 from './media/iw/CB09.jpg'
import cbVid from './media/iw/CBvid.mp4'


import Exp1 from './media/Exp/versa.gif'

import Exp2 from './media/Exp/01.jpg'
import Exp3 from './media/Exp/04.jpg'

import crayola1 from './media/Exp/05.jpg'
import crayola2 from './media/Exp/06.jpg'

import crayola3 from './media/Exp/cr.jpg'

import inf1 from './media/Exp/12.jpg'
import inf2 from './media/Exp/inf1.jpg'
import inf3 from './media/Exp/inf3.jpg'


import cb from './media/circ/CB.jpg'

import res1 from './media/circ/resource1.jpg'
import res2 from './media/circ/resource2.jpg'
import res3 from './media/circ/resource3.jpg'


import cir1 from './media/circ/circ1.jpg'
import cir2 from './media/circ/cir2.jpg'

import wip1 from './media/circ/client.jpg'
import wip2 from './media/circ/dashboard.jpg'
import wip3 from './media/circ/wip2.jpg'


import bs1 from './media/cw/events.jpg'
import bs2 from './media/cw/chapter.jpg'
import bs3 from './media/cw/lobby.jpg'
import lobbyOld from './media/cw/lobby-old.jpg'
import offerOld from './media/cw/offer-old.jpg'
import bs4 from './media/cw/LTO.jpg'
import bs5 from './media/cw/settings-a.jpg'
import settingsOld from './media/cw/settingsOld.jpg'


import mix1 from './media/cw/bigger_button.jpg'
import mix2 from './media/cw/capsules.jpg'
import mix3 from './media/cw/powerups.jpg'

import mix4 from './media/cw/sp.jpg'


import monkey1 from './media/cw/usurer.jpg'
import monkey2 from './media/cw/messiah.jpg'


 
import chest from './media/cw/chest.jpg'

import chestG from './media/cw/chest-with-tokens.gif'
 



//import ReactGA from 'react-ga'; 

import smoothscroll from 'smoothscroll-polyfill';


import {logEvent } from "firebase/analytics";
import analytics from "./firebase"







// kick off the polyfill!
smoothscroll.polyfill();

 


var content = {
  project0: {
    img: Still,
    title: 'Watch my Reel',
    content: "Motion graphics, visual effects, 3d animation and more",
    url:''
  },


  project1: {
    img: chest,
    title: 'Mobile Games',
    content: "Infinite World - Applovin",
    url:'/update'
  },


  project2: {
    img: Circ,
    title: 'Circadence',
    content: "Product Design & UI Development",
    url:'/ui'
  },
  project3: {
 


    img: Hersheys,
    title: "Hershey's Pelon Pelo Rico",
    content: "Project Management & Art Direction ",
    url:'/hersheys'
  },
  project4: {
    img: Exp,
    title: 'Experiences',
    content: "Beyond the screen",
    url:'/experiences'
  },
  project5: {
  
    img: BrandingBG,
    title: 'Branding',
    content: "Let me give your brand a memorable identity",
    url:'/branding'
  },
  project6: {
    img: Ill,
    title: 'Illustration',
    content: "I also like to draw",
    url:'/illustration'
  },


 

}



export const BackButton = (props) => {
    let history = useHistory();
    let temp = props;
    console.log('button', props.beenHome)
   
    return (
        <>
          <button className="back-button" onClick={(e) => {window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });document.documentElement.classList.add('back');
          setTimeout(function(props) {
            const checkIfScrollToIsFinished =    setInterval(() => {
              if (document.documentElement.scrollTop=== 0) {
                if(temp.beenHome){
              history.goBack();}
              else{
                history.push("/");
                temp.toggleHome()
              }
                  clearInterval(checkIfScrollToIsFinished);
              }
          }, 25);
          }, 500)

  
        
      }}>
<svg   viewBox="0 0 13 24" ><path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"></path></svg>

</button>
        </>
    );
};


const clicked=(project,going)=>{
  var el =document.getElementsByClassName('list')[0].children[project];
  var temp= el.getBoundingClientRect();

  if(going){
    document.documentElement.classList.add('going')
  }
 
 
//console.log(el,document.documentElement.scrollTop,el.offsetTop)

//el.scrollIntoView({behavior: "smooth", block: "center", inline:'start'})
  document.documentElement.scrollTo({
    top:   document.documentElement.scrollTop+temp.top,
    left: 0,
    behavior: 'smooth'
  })
 

}


export const ProjectButton = (props) => {
  
 
  return (
    
      
     props.index==0? <div  onClick={props.toggleReel}   >
      
        <a  className={props.index == props.active ? "list-item active" : "list-item inactive"} id={"item" + props.index}> 
         {props.children} 
          </a>

          </div>:
          
          <DelayLink delay={500} to={props.url} clickAction={()=>clicked(props.index,true)}   >
      
          <a href={props.url}  className={props.index == props.active ? "list-item active" : "list-item inactive"} id={"item" + props.index}> 
           {props.children} 
            </a>
  
            </DelayLink>
      
  );
};


function Reel(props) {
  //document.documentElement.classList='';
 
  return (
   
     <div className="reel-wrapper" onClick={props.toggleReel} >  
    <div>
    <button className="back-button" onClick={props.toggleReel}>
    <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24"  ><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
</button> 
<div class="video-wrapper">   <iframe src="https://player.vimeo.com/video/215067561" width="640" height="272" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen='true' title='video'></iframe></div>
    </div> 
    </div>
  
    
    )}


    var EmbedVideo = function(props) {
      return (
          <div dangerouslySetInnerHTML={{ __html: `
           <video
             loop ="loop"
             muted = "muted"
             autoplay
             playsinline
             src="${Vid}"
             class="videobg"
           />
         ` }}></div>
      )
   }
 
function Home(props) {


 
  return (
    <>
{props.reel?<Reel toggleReel={props.toggleReel}/>:""}
{logEvent(analytics, 'home')}
    <div className="big-video">
      <div className='intro-blurb'>
        <h1>Everything is Possible</h1>
        <p>         I’m a multimedia designer living in Front Royal, Virginia.<br/>
Let’s talk about working together.<br/><br/>

email: carlos.contreras.h@gmail.com<br/>
insta: @doncarlitoscontreras</p>
      </div>
     

  <EmbedVideo/>


    </div>
    <div className="intro"><div>
          <h1><span>Hi, I'm Carlos Contreras.</span> <span>I 
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 24 24" >
 
 
 <path fill ="red" d="M12,21.4L10.6,20C5.4,15.4,2,12.3,2,8.5C2,5.4,4.4,3,7.5,3c1.7,0,3.4,0.8,4.5,2.1C13.1,3.8,14.8,3,16.5,3
   C19.6,3,22,5.4,22,8.5c0,3.8-3.4,6.9-8.6,11.5L12,21.4z"/>
 </svg><img className="gif" src={Gif} alt='intro'/>.</span>

  </h1>
         
          </div>
         
        </div>
        <div className="main-wrapper" id={"active" + props.active}>

        
          <div className="list-wrapper">
      
    

      

            <div className="list" /*style={{ transform: ` rotateY(${props.xpos ? 310+(props.xpos * -2) : 0}deg) rotateX(${props.ypos ? 17+props.ypos  : 0}deg) ` }}*/>

              {
                Object.entries(content).map((project, index) => (

                  <ProjectButton active={props.active} index={index} key={index} url={project[1 ].url} toggleReel={props.toggleReel}>
              
                 <img src={project[1].img} alt='project cover' />
               
                  </ProjectButton>
                ))
              }



            </div>
          </div>

          <div className="content-wrapper">

<div className="content"><h1>{props.active==8?"":content['project' + props.active].title}</h1><p>{props.active==8?"":content['project' + props.active].content}</p>
{props.active==0?

<button onClick={props.toggleReel}>Watch Reel  
<svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 16 8" >

<path d="M12,3H0v2h12v3l4-4l-4-4V3z"/>
</svg>

</button>:

<DelayLink delay={500}  to={props.active==8?"":content['project'+props.active].url}  clickAction={()=>clicked(props.active,true)}   >
<a href={props.active==8?"":content['project'+props.active].url} >

<button>Open Case Study  
<svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 16 8" >

<path d="M12,3H0v2h12v3l4-4l-4-4V3z"/>
</svg>

</button></a></DelayLink>}

</div>


</div>
          <div className="thumb-wrapper">
            
            {
              Object.entries(content).map((project, index) => (

                
                <a className={index == props.active ? "thumb active" : "thumb"} key={index}  id={index} onClick={()=>clicked(index,false)}></a>
              ))
            }
          </div>

        </div>
     
        <div className="outro">
        <div className='tridi-wrapper'>
        <iframe className='tridi' src='https://my.spline.design/untitled-6dafa3d628b017b43df4796bd40dc8f6/' title='3d' frameborder='0' width='100%' height='100%'/>
        </div>
        <div className='tridi-wrapper'>
       
        </div>
          <div className='blurb' >
          <h5>Designed and built by me.</h5>
       <div className="top">   <a href = {Pdf} target = "_blank"><button>Resume <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24" ><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg></button></a> <a   href="mailto:carlos.contreras.h@gmail.com"  ><button>Contact<svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24" ><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10h5v-2h-5c-4.34 0-8-3.66-8-8s3.66-8 8-8 8 3.66 8 8v1.43c0 .79-.71 1.57-1.5 1.57s-1.5-.78-1.5-1.57V12c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5c1.38 0 2.64-.56 3.54-1.47.65.89 1.77 1.47 2.96 1.47 1.97 0 3.5-1.6 3.5-3.57V12c0-5.52-4.48-10-10-10zm0 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"/></svg></button></a>
       </div>   
          </div>
       
        </div>


        
        </>
  );
}





function ProjectH(props) {
  //document.documentElement.classList='';
 
  return (
    <>
     <div className="back-button-wrapper" >  <BackButton beenHome={props.beenHome} toggleHome={props.toggleHome}/></div>  
    <div className="project-wrapper" id={"project3"}>

   
      <div className="image-wrapper">
      
    <img src={content['project3'].img} alt='Hersheys'/></div>
    <h1>Hershey's Pelon Pelo Rico</h1>

    <span id={'arrow'}>↓</span>
    </div>

    <div className="project-content pelon res">
    
    <div className="big-image"> <img src={Pelon2} /></div> 
    <div className="big-image">    <img src={Pelon3} /></div> 
    <div className="big-image">   <img src={PelonG} /></div> 
    <div className="big-image">   <img src={Pelon4} /></div> 

    <div className="foot-note-wrapper">
      <div className="foot-note"><h2>Endless Runner</h2><h5>Project Management & Art Direction</h5><p>Pelon Pelo Rico is Hershey’s most sold product in Mexico. In previous years, giving away prizes for proofs of purchase resulted in local distributors getting all the prizes before even selling the product. To level the playing field in 2014 I suggested the proofs of purchase be used as power ups to play our “endless runner” type game available on the iOS and Google Play Stores. With a website where you could redeem over 70 million product codes and a live leaderboard, the top scores every week got prizes.</p></div></div>
    </div>
  
    
    </>)}


function ProjectCW(props) {
  //document.documentElement.classList='';
 
  return (
    <>
     <div className="back-button-wrapper" >  <BackButton beenHome={props.beenHome} toggleHome={props.toggleHome}/></div>  
    <div className="project-wrapper" id={"project1"}>

   
      <div className="image-wrapper">
      
    <img src={content['project1'].img} alt='chest' /></div>
    <h1>Mobile Games</h1>
    <span id={'arrow'}>↓</span>
    </div>

    <div className="project-content res">
      <h1>Cosmic Bingo</h1>
    
      <div className="big-image portrait">  <video
             controls
             muted
             autoPlay
             src={cbVid}
             class="videobg"
           /></div> 
    
    <div className="big-image portrait"> <img src={cb01} /></div> 
    <div className="big-image portrait"> <img src={cb02} /></div> 
    <div className="big-image portrait"> <img src={cb03} /></div> 
    <div className="big-image portrait"> <img src={cb04} /></div> 
    <div className="big-image portrait"> <img src={cb05} /></div> 
    <div className="big-image portrait"> <img src={cb06} /></div> 
    <div className="big-image portrait"> <img src={cb07} /></div> 
    <div className="big-image portrait"> <img src={cb08} /></div> 
    <div className="big-image portrait"> <img src={cb09} /></div> 
  

    <div className="foot-note-wrapper">
      <div className="foot-note"><h2>Cosmic Bingo</h2><h5>UI Design and Integration</h5><p>At <a href='https://infinite.world/' target="_blank">Infinite World</a> I designed the UI for Cosmic Bingo. I was also in charge of creating the prefabs and using Playmaker node based visual scripting to format and populate data from backend and control all frontend logic, animations and interactions within Unity.</p></div></div>
    </div>

    <div className="project-content res">
      <h1>Bingo Story</h1>
    
    <div className="big-image"> <img src={bs1} /></div> 
    <div className="big-image">    <img src={bs2} /></div> 
    <h5>Before & After</h5>
    <div className="side-by-side">   <img src={lobbyOld} /> <img src={bs3} /></div> 
    <h5>Before & After</h5>
    <div className="side-by-side">   <img src={settingsOld} /> <img src={bs5} /></div> 
    <h5>Before & After</h5>
    <div className="side-by-side">   <img src={offerOld} /> <img src={bs4} /></div> 

    <div className="foot-note-wrapper">
      <div className="foot-note"><h2>Bingo Story</h2><h5>UI Design and Integration</h5><p>With over 5 million downloads Bingo Story is Clipwire's star product. When I joined the team in June 2021, Bingo Story's UX and UI left a lot to be desired, it felt like an amateur product and did not reflect the quality of the product at all.</p></div></div>
    </div>
    <div className="project-content res">
      <h1>Solitaire: Pet Story</h1>
      <div className="big-image"> <img className='logo' src={sblogo} /></div> 
    
    <div className="big-image"> <img src={sb01} /></div> 
    <div className="big-image">    <img src={sb09} /></div> 
   
    <div className="big-image">    <img src={sb03} /></div> 
    <div className="big-image">    <img src={sb02} /></div> 
    <div className="big-image">    <img src={sb04} /></div> 
    <div className="big-image">    <img src={sb05} /></div> 
    <div className="big-image">    <img src={sb06} /></div> 
    <div className="big-image">    <img src={sb07} /></div> 
    <div className="big-image">    <img src={sb08} /></div> 
   
   
    <div className="foot-note-wrapper">
      <div className="foot-note"><h2>Solitaire: Pet Story</h2><h5>UI Design and Integration</h5><p> <a href='https://www.youtube.com/watch?v=SL_xEHbtimw&ab_channel=ClipwireGames' target='_blank'>Solitaire: Pet Story is a fun and relaxing card game filled with makeovers and pets.</a></p></div></div>
    </div>
    <div className="project-content res">
    <h1>Mixed Bag</h1>
    <div className="side-by-side"> 
  
     <img src={mix1}  />   <img src={mix3} /></div> 

     <div className="big-image chestg"> 
   <img src={chestG} />
   
 
   </div> 

     <div className="big-image"> 
   <img src={mix2} /></div> 
   <div className="big-image"> 
   <img src={mix4} />
   
 
   </div> 

 
 
 


   <div className="foot-note-wrapper">
     <div className="foot-note"><h2>Mixed Bag</h2><h5>Various work done at clipwire</h5><p>UI and assets for various products</p></div></div>
   </div>


 
  
    
    </>)}



function ProjectC(props) {
  //document.documentElement.classList='';
 
  return (
    <>
     <div className="back-button-wrapper" >  <BackButton beenHome={props.beenHome} toggleHome={props.toggleHome}/></div>  
    <div className="project-wrapper" id={"project2"}>

  
      <div className="image-wrapper">
      
    <img src={Circ} /></div>
    <h1>Circadence</h1>
    <span id={'arrow'}>↓</span>
    </div>

    <div className="project-content res">
   
    <div className="big-image"> <img src={cir1} /></div> 
    <div className="big-image"> <img src={cir2} /></div> 

    <div className="foot-note-wrapper">
      <div className="foot-note"><h2>Circadence Website</h2><h5>Product Design, Product Development & Webmaster</h5><p>Changes in the company structure and objectives left them with a Wordpress website that was dated and did not reflect the company at all. After a visual refresh to the company’s brand and re-evaluation of the websites objectives, I designed and built a <a target='_blank' href="https://circadence.com/">website</a> focused on generating MQLs using React and Gatsby.</p></div></div>
    </div>
    <div className="project-content res">
   
    <div className="big-image"> <img src={cb} /></div> 
     

   <div className="foot-note-wrapper">
     <div className="foot-note"><h2>CyberBridge</h2><h5>Product Design & UI Development</h5><p>Admin dashboard for the Project Ares Learning Platform. I created a design system using MUI and React that has been in use for a couple of years. It will soon be replaced by a new one I’m currently working on. <a target='_blank'  href="https://cyberbridge2.circadence.com/login">Product</a> requires login but demos of some of the UI components can be seen <a  target='_blank' href="https://cyberbridge-daa3c.web.app/">here.</a></p></div></div>
   </div>

  

   <div className="project-content res">
  
   <div className="big-image"> <img src={res1} /></div> 
    <div className="big-image"> <img src={res2} /></div> 
    <div className="big-image"> <img src={res3} /></div> 
 


   <div className="foot-note-wrapper">
     <div className="foot-note"><h2>Resource Center</h2><h5>Product Design, Product Development & Webmaster</h5><p>Circadence’s main website was focused on getting new clients and lacking a support section for current clients; this resulted in excess support calls. We remedied this by putting together different resources with a  <a  target='_blank' href="https://resourcecenter.circadence.com/">self service support portal.</a> While its accessible to everyone, authentication and authorization determine the content a user can see</p></div></div>
   </div>



   <div className="project-content res">
   
   <div className="big-image"> <img src={wip1} /></div> 
    <div className="big-image"> <img src={wip2} /></div> 
    
 

   <div className="foot-note-wrapper">
     <div className="foot-note"><h2>Coming Soon</h2><h5>Product Design & UI Development</h5><p>Previously known as CyberBridge, this product is being rebranded and taking on some features from their main learning platform. I Created a new design language (using Chakra UI) to create a cohesive experience from the website to the final product.</p></div></div>
   </div>
    
    </>)}



    function ProjectB(props) {
      //document.documentElement.classList='';
     
      return (
        <>
         <div className="back-button-wrapper" >  <BackButton beenHome={props.beenHome} toggleHome={props.toggleHome}/></div>  
        <div className="project-wrapper" id={"project5"}>
    
      
          <div className="image-wrapper">
          
        <img src={content['project5'].img} /></div>
        <h1>Branding</h1>
        <span id={'arrow'}>↓</span>
        </div>
    
        <div className="project-content branding">
        
         <img src={B1} />
       
         <img src={B2} />
         <img src={B3} />
         <img src={B4} />
        <img src={B5} />
         <img src={B6} />
       <div className='gif-wrapper'> <img src={B7} /></div> 
         <img src={chela1} />
         <img src={chela2} />
         <img src={chela3} />
         <div className="big-image">  <img src={B8} /></div>
         <img src={B9} />
         <img src={B10} />
         <img src={B11} />
         <img src={B12} />
         <div className="big-image">   <img src={B13} /></div>
      
    
        <div className="foot-note-wrapper">
          <div className="foot-note"><h2>Branding</h2><p>A few examples of brand identity related work.

</p></div></div>
        </div>
      
        
        </>)}





function ProjectE(props) {
  //document.documentElement.classList='';
  return (
    <>
     <div className="back-button-wrapper" >  <BackButton beenHome={props.beenHome} toggleHome={props.toggleHome}/></div>  
    <div className="project-wrapper" id={"project4"}>

  
      <div className="image-wrapper">
      
    <img src={content['project4'].img} /></div>
    <h1>Experiences</h1>
    <span id={'arrow'}>↓</span>
    </div>

    <div className="project-content versa">
    <div class="video-wrapper">
    <iframe src="https://player.vimeo.com/video/60274527"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>
     <img src={Exp1} />
    <img src={Exp2} />

    <div className="image-wrapper">
    <img src={Exp3} /></div>

    <div className="foot-note-wrapper">
      <div className="foot-note"><h2>Interactive Car Installation</h2><h5>Concept & Art Direction</h5><p>Originally pitched for the Nissan Juke but the client decided to use it for the Nissan Versa. Piezo transducers installed all around the car and connected to an Arduino I programmed to output midi notes, would trigger audio samples from Ableton Live. We got people to come up to car and talk to our hostess about it and exchange their name and email for a movie ticket.</p></div></div>
    </div>

    <div className="project-content crayola">
    
     <img src={crayola1} />
    <img src={crayola2} />

    <div className="big-image">
    <img src={crayola3} /></div>

    <div className="foot-note-wrapper">
      <div className="foot-note"><h2>Playground & Rest Area </h2><h5>Concept & Art Direction</h5><p>Outdoor playground & rest area for Crayola in Six Flags Mexico. Colorful strokes originating from giant Crayola markers twist and turn to give shape to this location / photo op. </p></div></div>
    </div>

    <div className="project-content inf">
    <div class="video-wrapper">
    <iframe src="https://player.vimeo.com/video/215575475" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>
     <img src={inf1} />
    <img src={inf2} />

    <img src={inf3} />
    <img src={content['project4'].img} />

    <div className="foot-note-wrapper">
      <div className="foot-note"><h2>Infiniti Q50 Launch</h2><h5>Art Direction</h5><p>Infiniti Q50 projection installation for release party.</p></div></div>
    </div>
  
    
    </>)}




function ProjectI(props) {
  //document.documentElement.classList='';
 
  return (
    <>
     <div className="back-button-wrapper" >  <BackButton beenHome={props.beenHome} toggleHome={props.toggleHome}/></div>  
    <div className="project-wrapper" id={"project6"}>

   
      <div className="image-wrapper">
      
    <img src={Ill} /></div>
    <h1>Illustration</h1>
    <span id={'arrow'}>↓</span>
    </div>

    <div className="project-content ill">
    <img src={monkey1} />
    <img src={monkey2} />
    <img src={insta1} />
    <img src={insta2} />
    <img src={insta3} />
    <img src={insta4} />
    <img src={insta5} />
    <img src={insta6} />
    <img src={insta7} />
    <img src={insta8} />
    <img src={insta9} />
    <img src={insta10} />
    <img src={insta11} />
    <img src={insta12} />
    <img src={insta13} />
    <img src={insta14} />
   
    <div className="foot-note-wrapper">
      <div className="foot-note"><h2>Illustration</h2><h5>Digital & traditional</h5><p>Random collection of portraits</p></div></div>
   
    </div>
  
    
    </>)}


 
class App extends React.Component {vide
  constructor(props) {
    super(props);
     
    this.state = { xpos: 0, ypos: 0, active: 8, reel:false, close:false,beenHome:false };

  }

toggleReel=()=>{
  this.setState({reel:!this.state.reel})
}
 
toggleHome=()=>{
  this.setState({beenHome:true})


}
 


applyCursorRippleEffect(e) {
  const ripple = document.createElement("div");

  ripple.className = "ripple";
  document.body.appendChild(ripple);

 ripple.style.left = `${e.clientX}px`;
 ripple.style.top = `${e.clientY}px`; 

  ripple.style.animation = "ripple-effect .4s  linear";
  ripple.onanimationend = () => document.body.removeChild(ripple);

}
  componentDidMount = () => {


    document.addEventListener('click', (event) => {

      console.log('click')
      this.applyCursorRippleEffect(event)






    })
   

    

   console.log('main mount',window.location.pathname)
  if( window.location.pathname=='/'){
    this.setState({beenHome:true})
  }
 
    

    window.addEventListener('scroll', this.handleScroll, true);

    document.addEventListener('mousemove', (event) => {
      this.handleMouseMove(event)






    });


   


  }

  componentWillUnmount=()=>{
     
    window.addEventListener('scroll', this.handleScroll, true);

    document.addEventListener('mousemove', (event) => {
      this.handleMouseMove(event)






    });
  }

  handleMouseMove = (event) => {
    let xpos = ((event.clientX / window.innerWidth) * 2) - 1;
    let ypos = ((event.clientY / window.innerHeight) * 2) - 1;

    this.setState({ xpos: xpos, ypos: ypos })
  }

  handleScroll = () => {
 
 
    //animated perspective origin
    if( window.location.pathname=="/"){
  //  document.body.style.perspectiveOrigin = (window.innerWidth / 2) + "px " + (document.documentElement.scrollTop + (window.innerHeight / 2)) + "px";

    this.setState({scrolled:document.documentElement.scrollTop})
//check for active
    var elements = document.getElementsByClassName("list-item");


    for (let i = 0; i < elements.length; i++) {
      var rect = elements[i].getBoundingClientRect();

//console.log("scroll",i,this.state.active, Math.abs((rect.top +(elements[i].offsetHeight/2)) - (window.innerHeight / 2))/ (window.innerHeight / 2) )

      if (Math.abs((rect.top +(elements[i].offsetHeight/2)) - (window.innerHeight / 2))/ (window.innerHeight / 2)  < 1) {
        this.setState({ active: i })
      
      }
    }

    }

    else{
      document.body.style.perspectiveOrigin = "50vw 50vh";

    }



  }

 
  render() {
   
    return (
      <>

   

        <Switch>
        <Route path="/"   exact >
        
          <Home active={this.state.active} wipe={this.state.wipe}   scrolled={this.state.scrolled} reel={this.state.reel} toggleReel={this.toggleReel}/></Route>
          <Route path="/hersheys"   ><ProjectH active={this.state.active} beenHome={this.state.beenHome} toggleHome={this.toggleHome}/></Route>
          <Route path="/update"    ><ProjectCW active={this.state.active}  beenHome={this.state.beenHome} toggleHome={this.toggleHome}/></Route>
        <Route path="/ui"    ><ProjectC active={this.state.active}  beenHome={this.state.beenHome} toggleHome={this.toggleHome}/></Route>
        <Route path="/branding"  ><ProjectB active={this.state.active}  beenHome={this.state.beenHome} toggleHome={this.toggleHome}/></Route>
        <Route path="/experiences"   ><ProjectE active={this.state.active}  beenHome={this.state.beenHome} toggleHome={this.toggleHome}/></Route>
        <Route path="/illustration"   ><ProjectI active={this.state.active}  beenHome={this.state.beenHome} toggleHome={this.toggleHome}/></Route>


           
            </Switch>
      </>

    )
  }
}

export default App;

 