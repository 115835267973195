import React from 'react';
import ReactGA from 'react-ga';
import { withRouter} from 'react-router-dom';

class Listener extends React.Component {
  componentDidMount(){
    document.documentElement.classList="";
    if( window.location.pathname=="/"){
      document.documentElement.classList.add('snap')
      }
  }
    componentWillMount() {
      this.unlisten = this.props.history.listen((location, action) => {
        document.documentElement.classList="";
        if( window.location.pathname=="/"){
            document.documentElement.classList.add('snap')
            }
            else{
                window.scrollTo(0, 0);
            }

            ReactGA.pageview(window.location.pathname + window.location.search);
 
      });
    }
    componentWillUnmount() {
        this.unlisten();
    }
    render() {
       return (
           <div>{this.props.children}</div>
        );
    }
  }
  export default withRouter(Listener);